// extracted by mini-css-extract-plugin
export var SubsidiarySubTitle = "big-blue-box-module--SubsidiarySubTitle--56b38";
export var SubsidiarySubTitleActive = "big-blue-box-module--SubsidiarySubTitleActive--fec14";
export var SubsidiaryTitle = "big-blue-box-module--SubsidiaryTitle--7bfe8";
export var SubsidiaryTitleActive = "big-blue-box-module--SubsidiaryTitleActive--a200e";
export var bigBlueBox = "big-blue-box-module--bigBlueBox--0437b";
export var body = "big-blue-box-module--body--18356";
export var bodyLarge = "big-blue-box-module--bodyLarge--123bd";
export var bodyLargeLight = "big-blue-box-module--bodyLargeLight--9bc99";
export var bodySmall = "big-blue-box-module--bodySmall--2149d";
export var bold = "big-blue-box-module--bold--3b9d5";
export var containerAdj = "big-blue-box-module--containerAdj--a3f5e";
export var eyebrow = "big-blue-box-module--eyebrow--7ec99";
export var eyebrowWhite = "big-blue-box-module--eyebrowWhite--95600";
export var filter = "big-blue-box-module--filter--72df5";
export var goldScript = "big-blue-box-module--goldScript--c52c5";
export var h2White = "big-blue-box-module--h2White--e3ae1";
export var homepageHeadline = "big-blue-box-module--homepageHeadline--89b1f";
export var imgWrap = "big-blue-box-module--imgWrap--f04d2";
export var internalHeadline = "big-blue-box-module--internalHeadline--65167";
export var italic = "big-blue-box-module--italic--76524";
export var link = "big-blue-box-module--link--50e9d";
export var linkSmall = "big-blue-box-module--linkSmall--481d5";
export var quote = "big-blue-box-module--quote--7bf92";