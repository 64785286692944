import * as React from "react";
import { useState } from "react";
import Panel from "./panel";
import Button from "./button";
import { hasNames } from "../utils";

import * as grd from "../styles/grid.module.scss";
import * as styles from "../styles/subsidiaries.module.scss";
import * as typ from "../styles/typography.module.scss";
import * as com from "../styles/common.module.scss";

import ExternalLinkIcon from "../images/external-link.icon.svg";
import RiveAnim from "./rive";

const Subsidiaries = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
  <>
    <div className={com.sectionSpacer}>
      <div className={hasNames(grd.container, styles.SubsidiariesMonobrow)}>The cynosure difference</div>
      <div className={hasNames(grd.gridContainer, styles.SubsidiariesCenterlineHeaderWrap)}>
      <h2 className={styles.SubsidiariesCenterlineHeader}>The Cynosure Group - Collaboration across four distinct business lines</h2>
      </div>
    </div>
    <div className={styles.Subsidiaries}>
    <div style={{
        width: `100%`,
        height: `100%`,
      }}>
      <RiveAnim state={activeIndex}/>
      </div>
      <div>
        <Panel
          ariaControls='Cynosure Partners'
          title="Cynosure Partners"
          subtitle="Private Growth Equity | Private Credit"
          isActive={activeIndex === 0}
          onShow={() => setActiveIndex(0)}
        >
        <p className={typ.bodyLarge}>Connect with the insight, energy, and creativity of a firm where investment professionals across multiple disciplines come together to work on your behalf.</p>
        <Button type="tertiarySolid" to="https://cynosurepartners.com/">
          Learn More <ExternalLinkIcon />
        </Button>
        </Panel>
        <Panel
          ariaControls='Cynosure Capital Management'
          title="Cynosure Capital Management"
          subtitle="Foundation and Endowment Portfolio Management"
          isActive={activeIndex === 1}
          onShow={() => setActiveIndex(1)}
        >
          <p>Advising foundations and endowments, our balanced approach to portfolio management helps our clients maximize their long-term impact.</p>
          <Button type="tertiarySolid" to="https://cynosurecapitalmanagement.com/">
          Learn More <ExternalLinkIcon />
          </Button>
        </Panel>
        <Panel
          ariaControls= 'Cynosure Wealth Advisors'
          title="Cynosure Wealth Advisors"
          subtitle="Integrated Wealth Management"
          isActive={activeIndex === 2}
          onShow={() => setActiveIndex(2)}
        >
          <p>Providing investment and wealth planning for ultra-high net worth individuals, we help clients minimize complexity and maximize opportunities.</p>
          <Button type="tertiarySolid" to="https://cynosurewealthadvisors.com/">
          Learn More <ExternalLinkIcon />
          </Button>
        </Panel>
        <Panel
          ariaControls= 'Cynosure Strategies'
          title="Cynosure Strategies"
          subtitle="Quantitative Hedge Fund"
          isActive={activeIndex === 3}
          onShow={() => setActiveIndex(3)}
        >
          <p>Employing a systematic long-short strategy, Cynosure Strategies aims for positive returns regardless of economic and market conditions.</p>
          <Button type="tertiarySolid" to="https://cynosurestrategies.co/">
          Learn More <ExternalLinkIcon />
          </Button>
        </Panel>
      </div>
    </div>
  </>)
}

export default Subsidiaries;
