import { hasNames } from "../utils";
import ContactUsModal from "./contact-us-modal";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { forwardRef } from "react";
import { useState } from "react";

import * as styles from "../styles/contact-us-footer.module.scss";

const ContactUsFooter = forwardRef((props, ref) => {
	const [showModal, setShowModal] = useState(false);
	const text =
		props.text || "Make us your first call for streamlined financial solutions. ";

	const toggleModal = () => {
		if (showModal) {
			document.body.classList.remove("scrollLocked");
		} else {
			document.body.classList.add("scrollLocked");
		}
		setShowModal((prev) => !prev);
	};

	return (
		<>
			<ContactUsModal isOpen={showModal} onRequestClose={toggleModal} />
			<div className={styles.wrap}>
				<div className={styles.container} ref={ref}>
					<div className={styles.left}>
						{/* <div className={styles.brow}>Contact Us</div> */}
						<h2 className={styles.message}>{text}</h2>
						{/* <Button onClick={toggleModal}>Let's Talk</Button> */}
					</div>
					<div className={hasNames(styles.right, styles.imageWrapper)}>
						<StaticImage
							className={styles.image}
							src="../images/landing-footer-1x.png"
							alt=""
							aria-hidden="true"
							layout="fullWidth"
						/>
					</div>
				</div>
			</div>
		</>
	);
});

export default ContactUsFooter;
