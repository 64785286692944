import { hasNames } from "../utils";
import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Frame from "../components/frame";
import { HeadMeta } from "../components/head-meta";

import Hero from "../components/hero";
import ContactUsFooter from "../components/contact-us-footer";
import BigBlueBox from "../components/big-blue-box";
import FiftyFifty from "../components/fifty-fifty";
import Button from "../components/button";
import Subsidiaries from "../components/subsidiaries";

import * as grd from "../styles/grid.module.scss";
import * as styles from "../styles/index.module.scss";
import * as com from "../styles/common.module.scss";
import * as typ from "../styles/typography.module.scss";

export const Head = () => {
	return <HeadMeta title="The Cynosure Group" />;
};

const IndexPage = ({ data }) => {
	return (
		<Frame section='Landing'>
			<div>
				<Hero image="Meeting"><h1>The Power of Collaborative Investments</h1></Hero>
				<Subsidiaries />
				<BigBlueBox>
					<div className={typ.eyebrowWhite}>
						What we do
					</div>
					<div className={typ.goldScript}>
					Helping Good People do Great Things
					</div>
					<h2 className={typ.h2White}>As a diversified investment firm, The Cynosure Group supports family offices, foundations, endowments, and like-minded investors who are seeking to build their wealth and maximize their impact in the world. </h2>
				</BigBlueBox>
				<div className={com.sectionSpacer}>
					<h2 className={hasNames(grd.container, com.centerlineHeader)}>
					Connect with the insight, energy, and creativity of a firm where investment professionals across multiple disciplines come together to work on your behalf.
					</h2>
				</div>
				<FiftyFifty reverseMobile={true}>
					<StaticImage
						src="../images/timeline/19th-B.jpg"
						alt="First Security Building"
						aspectRatio={1 / 1}
						layout="constrained"
						override={true}
						width={640}
						height={640}
						quality={85}
						className={hasNames(com.imageClippedLeft, com.fourty)}
					/>
					<div className={hasNames(com.sixty, "extra-gap")}>
						<div className={typ.eyebrow}>
						The Origin Story
						</div>
						<h2 className={com.browedHeader}>
						A modern investment approach based on principles tested over 150 years - the Eccles Family story
						</h2>
						<div className={typ.bodyLargeLight}>
						</div>
						<Button to="/our-history" type="primary" className={com.leftAlignedButton}>
						Our founding family’s history
						</Button>
					</div>
				</FiftyFifty>
				<ContactUsFooter />
			</div>
		</Frame>
	);
};

export default IndexPage;
