import { EmailDomains, hasNames } from "../utils";
import Button from "./button";
import * as React from "react";
import { useEffect, useState } from "react";
import Input from "react-phone-number-input/input";

import * as styles from "../styles/contact-us-form.module.scss";
import * as typ from "../styles/typography.module.scss";

const baseFormState = {
	firstName: { value: "", touched: false },
	lastName: { value: "", touched: false },
	email: { value: "", touched: false },
	company: { value: "", touched: false },
	reason: { value: "general", touched: false },
	message: { value: "", touched: false },
	phone: { value: "", touched: false },
	valid: false,
	touched: false,
};

const baseErrorState = {
	firstName: undefined,
	lastName: undefined,
	email: undefined,
	company: undefined,
	reason: undefined,
	message: undefined,
	phone: undefined,
};

const ContactUsForm = ({ onSubmit, showTitle = true }) => {
	// STATE ============================================================
	const [formState, setFormState] = useState(baseFormState);
	const [errorState, setErrorState] = useState(baseErrorState);
	const [numErrors, setNumErrors] = useState(0);
	const [showCompanyInput, setShowCompanyInput] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	// METHODS ============================================================
	const encode = (data) => {
		return Object.keys(data)
			.map(
				(key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
			)
			.join("&");
	};

	const handleSubmit = (event) => {
		forceTouchedState();
		validateForm();
		setSubmitting(true);

		event.preventDefault();

		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: encode({ "form-name": "contact", ...getFormVals() }),
		})
			.then(() => console.log("Form successfully submitted"))
			.catch((error) => alert(error));
	};

	const forceTouchedState = () => {
		const temp = { ...formState, touched: true };

		Object.keys(temp).forEach((key) => {
			if (temp[key] && temp[key].touched === false) {
				if (key === "company" && showCompanyInput === true) {
					temp[key].touched = true;
				} else {
					temp[key].touched = true;
				}
			}
		});

		setFormState(temp);
	};

	const getFormVals = () => {
		const vals = {};
		for (const key in formState) {
			vals[key] = formState[key].value;
		}

		return vals;
	};

	const handleBlur = (event) => {
		const { name } = event.target;
		if (formState[name].touched !== true) {
			setFormState((prevState) => ({
				...prevState,
				touched: true,
				[name]: { ...prevState[name], touched: true },
			}));
		}
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormState((prevState) => ({
			...prevState,
			[name]: { value: value, touched: true },
		}));
	};

	const handlePhonechange = (value) => {
		setFormState((prevState) => ({
			...prevState,
			phone: { value: value, touched: true },
		}));
	};

	const handleEmailChange = (event) => {
		const { name, value } = event.target;
		const domain = value.split("@")[1];

		if (!!!EmailDomains.includes(domain)) {
			setShowCompanyInput(true);
		} else {
			setShowCompanyInput(false);
		}

		setFormState((prevState) => ({
			...prevState,
			[name]: { value: value, touched: true },
		}));
	};

	const validateForm = () => {
		const tempErrors = { ...errorState };
		const preCheckCopy = JSON.stringify(tempErrors);

		for (let key in formState) {
			if (formState[key].touched === true) {
				// add error if the input has been touched and the value is empty
				if (formState[key].value === "" && tempErrors[key] === undefined) {
					if (key === "company" && tempErrors.company !== undefined) {
						tempErrors.company = undefined;
						setNumErrors((prev) => (prev -= 1));
					} else {
						tempErrors[key] = "Field is required";
						setNumErrors((prev) => (prev += 1));
					}
				}

				if (
					key === "company" &&
					showCompanyInput === false &&
					formState.company !== undefined
				) {
					tempErrors.company = undefined;
					setNumErrors((prev) => (prev -= 1));
				}

				// remove error when conditions satisfy
				if (formState[key].value !== "" && tempErrors[key] !== undefined) {
					tempErrors[key] = undefined;
					setNumErrors((prev) => (prev -= 1));
				}
			}

			const postCheckCopy = JSON.stringify(tempErrors);
			if (preCheckCopy !== postCheckCopy) {
				setErrorState(tempErrors);
			}
		}
	};

	// HOOKS ============================================================
	useEffect(validateForm, [formState, errorState, showCompanyInput]);

	useEffect(() => {
		if (numErrors === 0) {
			setFormState((prevState) => ({ ...prevState, valid: true }));
		} else {
			setFormState((prevState) => ({ ...prevState, valid: false }));
		}
	}, [numErrors, formState.touched]);

	useEffect(() => {
		if (submitting) {
			if (formState.valid) {
				setSubmitting(false);
				setFormState(baseFormState);
				setErrorState(baseErrorState);
				setNumErrors(0);
			} else {
				console.error("Form is invalid", errorState);
				setSubmitting(false);
			}
		}
	}, [submitting, errorState, formState.valid]);

	return (
		<form
			name="contact"
			method="POST"
			data-netlify="true"
			action="/contact-us"
			onSubmit={handleSubmit}>
			<div className={styles.formWrapper}>
				{showTitle && <h2>Contact Cynosure</h2>}
				<div className={styles.formRowFlex}>
					<div className={styles.inputWithLabel}>
						<label htmlFor="first-name">
							<div className={typ.bodySmall}>First Name</div>
						</label>
						<input
							id="first-name"
							autoComplete="on"
							type="text"
							name="firstName"
							className={`${
								errorState.firstName !== undefined ? styles.error : ""
							}`}
							value={formState.firstName.value}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
						{errorState.firstName !== undefined && (
							<div className={styles.errorMessage}>
								Please enter your first name
							</div>
						)}
					</div>
					<div className={styles.inputWithLabel}>
						<label htmlFor="last-name">
							<div className={typ.bodySmall}>Last Name</div>
						</label>
						<input
							id="last-name"
							autoComplete="on"
							type="text"
							name="lastName"
							value={formState.lastName.value}
							className={`${
								errorState.lastName !== undefined ? styles.error : ""
							}`}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
						{errorState.lastName !== undefined && (
							<div className={styles.errorMessage}>
								Please enter your last name
							</div>
						)}
					</div>
				</div>
				{/* end of row */}
				<div className={styles.formRowCentered}>
					<div className={styles.inputWithLabel}>
						<label htmlFor="email">
							<div className={typ.bodySmall}>Work Email</div>
						</label>
						<input
							id="email"
							type="text"
							autoComplete="on"
							name="email"
							className={`${
								errorState.email !== undefined ? styles.error : ""
							}`}
							value={formState.email.value}
							onBlur={handleBlur}
							onChange={handleEmailChange}
						/>
						{errorState.email !== undefined && (
							<div className={styles.errorMessage}>
								Please enter a valid email
							</div>
						)}
					</div>
				</div>
				{/* end of row  */}
				{showCompanyInput && (
					<div className={styles.formRowCentered}>
						<div className={styles.inputWithLabel}>
							<label htmlFor="company">
								<div className={typ.bodySmall}>Company Name</div>
							</label>
							<input
								id="company"
								type="text"
								autoComplete="on"
								name="company"
								className={`${
									errorState.company !== undefined ? styles.error : ""
								}`}
								value={formState.company.value}
								onBlur={handleBlur}
								onChange={handleChange}
							/>
							{errorState.company !== undefined && (
								<div className={styles.errorMessage}>
									Please enter your company name
								</div>
							)}
						</div>
					</div>
				)}
				{/* end of row  */}
				<div className={styles.formRowStart}>
					<p className={typ.bodyLarge}>Your reason for contacting us today</p>
				</div>
				<div
					className={hasNames(
						styles.radioGroup,
						`${errorState.reason !== undefined ? styles.error : ""}`
					)}>
					<div className={hasNames(styles.radioOption, typ.bodySmall)}>
						<input
							type="radio"
							value="general"
							name="reason"
							checked={formState.reason.value === "general"}
							onBlur={handleBlur}
							onChange={handleChange}
						/>
						General Inquiry
					</div>
					<div className={hasNames(styles.radioOption, typ.bodySmall)}>
						<input
							type="radio"
							value="investment"
							name="reason"
							checked={formState.reason.value === "investment"}
							onBlur={handleBlur}
							onChange={handleChange}
						/>{" "}
						Investment Opportunity
					</div>
					<div className={hasNames(styles.radioOption, typ.bodySmall)}>
						<input
							type="radio"
							value="portfolio"
							name="reason"
							checked={formState.reason.value === "portfolio"}
							onBlur={handleBlur}
							onChange={handleChange}
						/>{" "}
						Portfolio Company Partnership
					</div>
				</div>
				{/* end of row  */}
				<div className={styles.messageSection}>
					<p>Message</p>
					<textarea
						name="message"
						className={`${
							errorState.message !== undefined ? styles.error : ""
						}`}
						value={formState.message.value}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
					{errorState.message !== undefined && (
						<div className={styles.errorMessage}>Please enter a message</div>
					)}
				</div>
				{/* end of row  */}
				<div className={styles.formRowStart}>
					<div className={styles.inputWithLabel}>
						<label htmlFor="phone-number">
							<div className={typ.bodyLarge}>Phone Number</div>
						</label>
						<Input
							country="US"
							placeholder="(___)&nbsp;___-____"
							name="phone"
							className={`${
								errorState.phone !== undefined ? styles.error : ""
							}`}
							value={formState.phone.value}
							onBlur={handleBlur}
							onChange={handlePhonechange}
						/>
						{errorState.phone !== undefined && (
							<div className={styles.errorMessage}>
								Please enter a valid phone number
							</div>
						)}
					</div>
				</div>
				{/* end of row  */}
				<div className={styles.submitFooter}>
					<Button variety="submit">Contact Cynosure</Button>
					<div className={hasNames(styles.contactNumber, typ.bodySmall)}>
						Or call us at +1 (801) 521-3100
					</div>
				</div>
			</div>
		</form>
	);
};

export default ContactUsForm;
