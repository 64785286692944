import BaseModal from "./base-modal";
import ContactUsForm from "./contact-us-form";
import * as React from "react";

const ContactUsModal = ({ isOpen, onRequestClose }) => {
	return (
		<BaseModal
			width={864}
			onRequestClose={onRequestClose}
			isOpen={isOpen}
			Content={<ContactUsForm onSubmit={onRequestClose} />}
		/>
	);
};

export default ContactUsModal;
