import * as React from "react";
import { useState } from "react";
import { hasNames } from "../utils";

import * as typ from "../styles/typography.module.scss";
import * as styles from "../styles/panel.module.scss";

import ChevronDownIcon from "../images/chevron-down.icon.svg";
import ChevronUpIcon from "../images/chevron-up.icon.svg";

const Panel = ({title, subtitle, children, isActive, onShow, ariaControls}) => {
  const [isExpanded, ] = useState(false);
  const contentDescription = 'Accordion Content';

  return (
    <section className={styles.Panel}>
      <div
        className={hasNames(isActive ? (typ.SubsidiaryTitleActive):(typ.SubsidiaryTitle), styles.PanelTitle)}
        aria-controls={ariaControls}
        aria-label={(isExpanded ? "hide " : "show ") + contentDescription}
        onClick={()=>{
          onShow()
          }}>
          {title}
          <div className={styles.Chevron}>{isActive ? (<ChevronDownIcon />):(<ChevronUpIcon />)}</div>
        </div>
        <div className={isActive ? (typ.SubsidiarySubTitleActive):(typ.SubsidiarySubTitle)}>{subtitle}</div>
      {isActive && (
        <>{children}</>
      )}
    </section>
  );
}

export default Panel;
