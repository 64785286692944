// extracted by mini-css-extract-plugin
export var SubsidiarySubTitle = "typography-module--SubsidiarySubTitle--fb7e3";
export var SubsidiarySubTitleActive = "typography-module--SubsidiarySubTitleActive--ffcb3";
export var SubsidiaryTitle = "typography-module--SubsidiaryTitle--86cd9";
export var SubsidiaryTitleActive = "typography-module--SubsidiaryTitleActive--2d6c2";
export var body = "typography-module--body--0a6b2";
export var bodyLarge = "typography-module--bodyLarge--6084d";
export var bodyLargeLight = "typography-module--bodyLargeLight--f23bb";
export var bodySmall = "typography-module--bodySmall--34bb2";
export var bold = "typography-module--bold--b241f";
export var eyebrow = "typography-module--eyebrow--8b0de";
export var eyebrowWhite = "typography-module--eyebrowWhite--e3a92";
export var filter = "typography-module--filter--5428e";
export var goldScript = "typography-module--goldScript--a6479";
export var h2White = "typography-module--h2White--6a3fc";
export var homepageHeadline = "typography-module--homepageHeadline--4ec29";
export var internalHeadline = "typography-module--internalHeadline--e9fc2";
export var italic = "typography-module--italic--73c11";
export var link = "typography-module--link--c617e";
export var linkSmall = "typography-module--linkSmall--e5850";
export var quote = "typography-module--quote--2f6f9";