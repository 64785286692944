import { hasNames } from "../utils";
import Button from "./button";
import * as React from "react";
import Modal from "react-modal";

import ChevronLeft from "../images/chevron-left.icon.svg";
import ChevronRight from "../images/chevron-right.icon.svg";
import CrossIcon from "../images/cross.icon.svg";

import * as styles from "../styles/base-modal.module.scss";

const BaseModal = ({
	title,
	isOpen = false,
	onRequestClose,
	Content,
	Footer,
	shouldCloseOnOverlayClick = true,
	showCloseButton = true,
	next = null,
	previous = null,
	modalClass = null,
	limitHeight = false,
}) => {
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			contentLabel={title}
			className={hasNames(
				styles.modalWrap,
				modalClass,
				limitHeight ? styles.maxHeight : ""
			)}
			ariaHideApp={false}
			shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}>
			<div className={hasNames(styles.modal, "pineapples")}>
				{previous === null ? null : (
					<Button
						className={hasNames(styles.arrows, styles.prev)}
						type="modal"
						onClick={previous}>
						<ChevronLeft />
					</Button>
				)}
				{next === null ? null : (
					<Button
						className={hasNames(styles.arrows, styles.next)}
						type="modal"
						onClick={next}>
						<ChevronRight />
					</Button>
				)}
				{showCloseButton ? (
					<CrossIcon className={styles.close} onClick={onRequestClose} />
				) : null}
				{/* {title && (
		            <div className={styles.titleWrapper}>
        		      <h1 className={styles.modalTitle}>{title}</h1>
					</div>
				)} */}
				<div>{Content}</div>
				<div className={styles.footer}>{Footer}</div>
			</div>
		</Modal>
	);
};

export default BaseModal;
