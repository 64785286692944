import { hasNames } from "../utils";
import { Link } from "gatsby";
import * as React from "react";

import * as styles from "../styles/button.module.scss";

const Button = ({
	to = "/404/",
	onClick = null,
	externalHoverState = false,
	type = "primary",
	compact = false,
	className = "",
	children,
	ariaLabel = "",
	target = null,
	active = false,
	variety = "button",
}) => {
	if (onClick !== null && to === "/404/") {
		return (
			<button
				onClick={onClick}
				className={hasNames(
					styles[type],
					compact ? styles.compact : "",
					className,
					externalHoverState ? "hovering" : "",
					active ? "active" : ""
				)}
				aria-label={ariaLabel}>
				{children}
			</button>
		);
	} else if (target !== null) {
		return (
			<a
				href={to}
				target={target}
				className={hasNames(
					styles[type],
					compact ? styles.compact : "",
					className,
					externalHoverState ? "hovering" : "",
					active ? "active" : ""
				)}>
				{children}
			</a>
		);
	} else if (variety === "submit") {
		return (
			<button
				type="submit"
				className={hasNames(
					styles["primary"],
					className,
					externalHoverState ? "hovering" : "",
					active ? "active" : ""
				)}>
				{children}
			</button>
		);
	}
	return (
		<Link
			to={to}
			onClick={onClick}
			className={hasNames(
				styles[type],
				compact ? styles.compact : "",
				className,
				externalHoverState ? "hovering" : ""
			)}>
			{children}
		</Link>
	);
};

export default Button;
