import { hasNames } from "../utils";
import * as React from "react";
import { forwardRef } from "react";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "../styles/big-blue-box.module.scss";
import * as grd from "../styles/grid.module.scss";

const BigBlueBox = forwardRef((props, ref) => {
	const { children } = props;

	return (
		<div ref={ref} className={hasNames(styles.bigBlueBox)}>
			<div className={hasNames(grd.container, styles.containerAdj)}>
        <div className={styles.imgWrap}>
					<StaticImage
							src="../images/logo.png"
							alt="logomark"
							aspectRatio={1 / 1}
							layout="constrained"
							width={66}
							height={44}
							quality={85}
						/>
					</div>
				{children}
			</div>
		</div>
	);
});

export default BigBlueBox;
