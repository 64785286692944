import * as React from "react";
import { useRive, useStateMachineInput } from '@rive-app/react-canvas';
import subsidiaryAnim from "../images/rive/landing.riv";

export default function RiveAnim( props ) {
  const { rive, RiveComponent } = useRive({
    src: subsidiaryAnim,
    stateMachines: "State Machine 1",
    autoplay: true,
  });

  const statePartners = useStateMachineInput(rive, "State Machine 1", "T_Partners");
  const stateCapitalManagment = useStateMachineInput(rive, "State Machine 1", "T_CCM");
  const stateWealthAdvisors = useStateMachineInput(rive, "State Machine 1", "T_CWA");
  const stateStrategies = useStateMachineInput(rive, "State Machine 1", "T_Strategies");

  React.useEffect(()=>{
    (()=>{
      switch(props.state){
        case 0:
          statePartners?.fire()
        break;
        case 1:
          stateCapitalManagment?.fire()
        break;
        case 2:
          stateWealthAdvisors?.fire()
        break;
        case 3:
          stateStrategies?.fire()
        break;
        default:
        break;
      }
    })()
  }, [props.state])

  return (
      <RiveComponent />
  );
}
