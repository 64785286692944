// extracted by mini-css-extract-plugin
export var Chevron = "panel-module--Chevron--4440c";
export var Panel = "panel-module--Panel--24d97";
export var PanelTitle = "panel-module--PanelTitle--792cc";
export var SubsidiarySubTitle = "panel-module--SubsidiarySubTitle--28780";
export var SubsidiarySubTitleActive = "panel-module--SubsidiarySubTitleActive--86248";
export var SubsidiaryTitle = "panel-module--SubsidiaryTitle--3b658";
export var SubsidiaryTitleActive = "panel-module--SubsidiaryTitleActive--7c120";
export var body = "panel-module--body--feac0";
export var bodyLarge = "panel-module--bodyLarge--cf372";
export var bodyLargeLight = "panel-module--bodyLargeLight--6be51";
export var bodySmall = "panel-module--bodySmall--98c82";
export var bold = "panel-module--bold--175e3";
export var eyebrow = "panel-module--eyebrow--d42dd";
export var eyebrowWhite = "panel-module--eyebrowWhite--44615";
export var filter = "panel-module--filter--5b68d";
export var goldScript = "panel-module--goldScript--ff3e9";
export var h2White = "panel-module--h2White--478cd";
export var homepageHeadline = "panel-module--homepageHeadline--67a53";
export var internalHeadline = "panel-module--internalHeadline--08ffc";
export var italic = "panel-module--italic--f9709";
export var link = "panel-module--link--9c65a";
export var linkSmall = "panel-module--linkSmall--df161";
export var quote = "panel-module--quote--75b0f";